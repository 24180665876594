<template>
  <div class="content p-t-10 p-l-10 p-r-10 ">
    <van-field v-model="form.damage_code_sn" center clearable label="损坏二维码" placeholder="请输入损坏二维码" required>
    </van-field>
    <van-field v-model="form.replace_code_sn" center clearable label="替换二维码" autocomplete="off" placeholder="请输入二维码" required>
    </van-field>
    <van-button color="linear-gradient(to right, #53A1FF, #68D0FF)" round type="primary" block class="m-t-40" loading-text="替换中..." :loading="loading" @click="replace">替换</van-button>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import { replaceCode } from "@/api/replace.js";
import { Toast , Notify, Dialog} from "vant";
import { iscode, } from "@/utils/app";
export default {
  setup() {
    const form = ref({
      damage_code_sn: "",
      replace_code_sn: "",
    });
    const loading = ref(false);
    const replace =  () => {
      if (!form.value.damage_code_sn || !form.value.replace_code_sn) {
        Toast("请输入完整二维码");
        return;
      }
      if (form.value.damage_code_sn == form.value.replace_code_sn) {
        Toast("损坏二维码和替换二维码不能相同");
        return;
      }
      Dialog.confirm({
        message: "确定进行替换？",
        confirmButtonColor:'#5B9DFF',
      }).then(async() => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        let result = await replaceCode(form.value)
          .then((res) => res.data)
          .catch((error) => error);
        Toast.clear();
        if (iscode(result)) {
          Notify({ type: "success", message: "替换成功" });
          form.value = {
            damage_code_sn: "",
            replace_code_sn: "",
          };
        }else{
          Notify({ type: "danger", message: result.msg });
        }
      });
    };
    return {
      form,
      loading,
      replace,
    };
  },
};
</script>
